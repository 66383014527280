import React from "react";

const StudentDashboard = () => {
  return (
    <div>
      <h1>Students Dashboard</h1>
    </div>
  );
};

export default StudentDashboard;
