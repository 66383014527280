import React from 'react'


const TeacherDashboard = () => {
  return (
    <div>
     <h1>Teaacher's Dashboard</h1>
    </div>
  )
}

export default TeacherDashboard